import {Box, Container, Header} from "@amzn/awsui-components-react";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import LineChart from "@amzn/awsui-components-react/polaris/line-chart";
import Button from "@amzn/awsui-components-react/polaris/button";
import * as React from "react";
import {
    populateMapData,
    populateRRmapPlots,
    populateTicketMapData,
    populateTicketXDomain,
    populateXDomain
} from "../utils/CommonFns";

export default function ServiceMetricsContent({serviceDetails}){

//To display graphical representation of ticket information,resolution rate and service availability.
//Graph contains 3 months aggregated data only.
    return (
        <Container
            header={
                <div>
                    <Header variant="h2">
                        Service Metrics &nbsp;
                    </Header>
                </div>
            }>
            <ColumnLayout columns={3} borders="all" variant="text-grid">
                <div>
                    <LineChart
                        series={[
                            {
                                title: serviceDetails.fleetName,
                                type: "line",
                                data: populateTicketMapData(serviceDetails.fleetTickets),
                            }
                        ]}
                        xDomain={populateTicketXDomain(serviceDetails.fleetTickets)}
                        ariaLabel="Single data series line chart"
                        errorText="Error loading data."
                        height={250}
                        hideFilter
                        hideLegend
                        loadingText="Loading chart"
                        recoveryText="Retry"
                        xScaleType="categorical"
                        xTitle=""
                        yTitle="Incoming Tickets"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    /></div>
                <div><LineChart
                    series={[
                        {
                            title: serviceDetails.fleetName,
                            type: "line",
                            data: populateRRmapPlots(serviceDetails.fleetTickets),
                        },
                        {
                            title: "RR goal %",
                            type: "threshold",
                            y: 75
                        }
                    ]}
                    xDomain={populateTicketXDomain(serviceDetails.fleetTickets)}
                    detailPopoverSize={'medium'}
                    ariaLabel="Single data series line chart"
                    errorText="Error loading data."
                    height={250}
                    hideFilter
                    hideLegend
                    loadingText="Loading chart"
                    recoveryText="Retry"
                    xScaleType="categorical"
                    xTitle=""
                    yTitle="Resolution Rate %"
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No data available</b>
                            <Box variant="p" color="inherit">
                                There is no data available
                            </Box>
                        </Box>
                    }
                    noMatch={
                        <Box textAlign="center" color="inherit">
                            <b>No matching data</b>
                            <Box variant="p" color="inherit">
                                There is no matching data to display
                            </Box>
                            <Button>Clear filter</Button>
                        </Box>
                    }
                /></div>
                <div><LineChart
                    series={[
                        {
                            title: serviceDetails.fleetName,
                            type: "line",
                            data: populateMapData(serviceDetails.availabilityPlots),
                        }
                    ]}
                    xDomain={populateXDomain(serviceDetails.availabilityPlots)}
                    ariaLabel="Single data series line chart"
                    errorText="Error loading data."
                    height={250}
                    hideFilter
                    hideLegend
                    loadingText="Loading chart"
                    recoveryText="Retry"
                    xScaleType="categorical"
                    xTitle=""
                    yTitle="Availability %"
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No data available</b>
                            <Box variant="p" color="inherit">
                                There is no data available
                            </Box>
                        </Box>
                    }
                    noMatch={
                        <Box textAlign="center" color="inherit">
                            <b>No matching data</b>
                            <Box variant="p" color="inherit">
                                There is no matching data to display
                            </Box>
                            <Button>Clear filter</Button>
                        </Box>
                    }
                />
                </div>
            </ColumnLayout>
        </Container>
    );
}