/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI SIServices
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InfraManualOverrideModel
 */
export interface InfraManualOverrideModel {
    /**
     * 
     * @type {string}
     * @memberof InfraManualOverrideModel
     */
    'fleetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InfraManualOverrideModel
     */
    'currMonthYr'?: string;
    /**
     * 
     * @type {string}
     * @memberof InfraManualOverrideModel
     */
    'manualOverrideType'?: string;
    /**
     * 
     * @type {string}
     * @memberof InfraManualOverrideModel
     */
    'manualOverrideValue'?: string;
}
/**
 * 
 * @export
 * @interface InsightsAIModel
 */
export interface InsightsAIModel {
    /**
     * 
     * @type {string}
     * @memberof InsightsAIModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsAIModel
     */
    'trackingTT'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsAIModel
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsAIModel
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsAIModel
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface InsightsCPUModel
 */
export interface InsightsCPUModel {
    /**
     * 
     * @type {string}
     * @memberof InsightsCPUModel
     */
    'monthyear'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsCPUModel
     */
    'maxCpu'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsCPUModel
     */
    'avgCpu'?: string;
}
/**
 * 
 * @export
 * @interface InsightsRCModel
 */
export interface InsightsRCModel {
    /**
     * 
     * @type {string}
     * @memberof InsightsRCModel
     */
    'monthyear'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsRCModel
     */
    'rootcause'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsRCModel
     */
    'count'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsRCModel
     */
    'permFixCount'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsRCModel
     */
    'noFixCount'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsRCModel
     */
    'tempFixCount'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsRCModel
     */
    'comments'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsightsRCModel
     */
    'ticketsLink'?: string;
}
/**
 * 
 * @export
 * @interface InsightsTicket
 */
export interface InsightsTicket {
    /**
     * 
     * @type {string}
     * @memberof InsightsTicket
     */
    'monthyear'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsightsTicket
     */
    'incomings'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsightsTicket
     */
    'resolved'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsightsTicket
     */
    'resolvedRate'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsightsTicket
     */
    'sev2'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsightsTicket
     */
    'sev3'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsightsTicket
     */
    'sev4'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsightsTicket
     */
    'sev5'?: number;
}
/**
 * 
 * @export
 * @interface MapPlot
 */
export interface MapPlot {
    /**
     * 
     * @type {string}
     * @memberof MapPlot
     */
    'x'?: string;
    /**
     * 
     * @type {number}
     * @memberof MapPlot
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface SIOtherInfo
 */
export interface SIOtherInfo {
    /**
     * 
     * @type {string}
     * @memberof SIOtherInfo
     */
    'otherInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIOtherInfo
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface SIService
 */
export interface SIService {
    /**
     * 
     * @type {number}
     * @memberof SIService
     */
    'fleetId': number;
    /**
     * 
     * @type {string}
     * @memberof SIService
     */
    'fleetName': string;
    /**
     * 
     * @type {string}
     * @memberof SIService
     */
    'currMonthYr'?: string;
    /**
     * 
     * @type {Array<InsightsTicket>}
     * @memberof SIService
     */
    'fleetTickets'?: Array<InsightsTicket>;
    /**
     * 
     * @type {UpdateInfraModel}
     * @memberof SIService
     */
    'infraSettings'?: UpdateInfraModel;
    /**
     * 
     * @type {SIServiceMemoryCurrentMonth}
     * @memberof SIService
     */
    'memoryCurrentMonth'?: SIServiceMemoryCurrentMonth;
    /**
     * 
     * @type {SIServiceFleetCostData}
     * @memberof SIService
     */
    'fleetCostData'?: SIServiceFleetCostData;
    /**
     * 
     * @type {Array<InsightsRCModel>}
     * @memberof SIService
     */
    'fleetTopRootCauses'?: Array<InsightsRCModel>;
    /**
     * 
     * @type {Array<InsightsCPUModel>}
     * @memberof SIService
     */
    'cpuUtilizationPlots'?: Array<InsightsCPUModel>;
    /**
     * 
     * @type {Array<MapPlot>}
     * @memberof SIService
     */
    'cpuUtilizationDailyPlots'?: Array<MapPlot>;
    /**
     * 
     * @type {Array<MapPlot>}
     * @memberof SIService
     */
    'memoryDailyPlots'?: Array<MapPlot>;
    /**
     * 
     * @type {Array<MapPlot>}
     * @memberof SIService
     */
    'maxTpsPlots'?: Array<MapPlot>;
    /**
     * 
     * @type {SIServiceTpsDailyPlots}
     * @memberof SIService
     */
    'tpsDailyPlots'?: SIServiceTpsDailyPlots;
    /**
     * 
     * @type {Array<MapPlot>}
     * @memberof SIService
     */
    'memoryUtilizationPlots'?: Array<MapPlot>;
    /**
     * 
     * @type {Array<MapPlot>}
     * @memberof SIService
     */
    'availabilityPlots'?: Array<MapPlot>;
    /**
     * 
     * @type {Array<InsightsAIModel>}
     * @memberof SIService
     */
    'actionItems'?: Array<InsightsAIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SIService
     */
    'highlights'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SIService
     */
    'lowlights'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SIService
     */
    'notes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SIService
     */
    'tag'?: string;
}
/**
 * 
 * @export
 * @interface SIServiceFleetCostData
 */
export interface SIServiceFleetCostData {
    /**
     * 
     * @type {string}
     * @memberof SIServiceFleetCostData
     */
    'monthyear'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceFleetCostData
     */
    'monthlyActuals'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceFleetCostData
     */
    'monthlyEstimate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceFleetCostData
     */
    'monthlyStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceFleetCostData
     */
    'ytdCurrentEstiamte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceFleetCostData
     */
    'ytdGoal'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceFleetCostData
     */
    'ytdStatus'?: string;
}
/**
 * 
 * @export
 * @interface SIServiceMemoryCurrentMonth
 */
export interface SIServiceMemoryCurrentMonth {
    /**
     * 
     * @type {string}
     * @memberof SIServiceMemoryCurrentMonth
     */
    'monthYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceMemoryCurrentMonth
     */
    'maxMemory'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceMemoryCurrentMonth
     */
    'avgMemory'?: string;
}
/**
 * 
 * @export
 * @interface SIServiceTpsDailyPlots
 */
export interface SIServiceTpsDailyPlots {
    /**
     * 
     * @type {string}
     * @memberof SIServiceTpsDailyPlots
     */
    'monthYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceTpsDailyPlots
     */
    'max'?: string;
    /**
     * 
     * @type {string}
     * @memberof SIServiceTpsDailyPlots
     */
    'avg'?: string;
    /**
     * 
     * @type {Array<MapPlot>}
     * @memberof SIServiceTpsDailyPlots
     */
    'dailyPlots'?: Array<MapPlot>;
}
/**
 * 
 * @export
 * @interface ServiceConfig
 */
export interface ServiceConfig {
    /**
     * 
     * @type {string}
     * @memberof ServiceConfig
     */
    'fleetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConfig
     */
    'resolverGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConfig
     */
    'fleetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConfig
     */
    'primaryOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConfig
     */
    'supportOwner'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceConfig
     */
    'notifiers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceConfig
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface ServiceDetailsRequest
 */
export interface ServiceDetailsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDetailsRequest
     */
    'fleetNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetailsRequest
     */
    'month'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceDetailsRequest
     */
    'toEmails'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateAIModel
 */
export interface UpdateAIModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateAIModel
     */
    'fleetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAIModel
     */
    'created'?: string;
    /**
     * 
     * @type {Array<InsightsAIModel>}
     * @memberof UpdateAIModel
     */
    'actionItems'?: Array<InsightsAIModel>;
}
/**
 * 
 * @export
 * @interface UpdateInfraModel
 */
export interface UpdateInfraModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'fleetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'marketplaceUS'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'marketplaceEU'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'marketplaceFE'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'hostgroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'host'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'servicename'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'methodname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'client'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'metricClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'instance'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfraModel
     */
    'metric'?: string;
}
/**
 * 
 * @export
 * @interface UpdateOtherInfoModel
 */
export interface UpdateOtherInfoModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateOtherInfoModel
     */
    'fleetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOtherInfoModel
     */
    'monthYear'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOtherInfoModel
     */
    'otherInfo'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateOtherInfoModel
     */
    'updateType'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRootcauseModel
 */
export interface UpdateRootcauseModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateRootcauseModel
     */
    'fleetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRootcauseModel
     */
    'monthYear'?: string;
    /**
     * 
     * @type {Array<InsightsRCModel>}
     * @memberof UpdateRootcauseModel
     */
    'rootcauses'?: Array<InsightsRCModel>;
}

/**
 * CORSApi - axios parameter creator
 * @export
 */
export const CORSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get/service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CORSApi - functional programming interface
 * @export
 */
export const CORSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CORSApiAxiosParamCreator(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CORSApi - factory interface
 * @export
 */
export const CORSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CORSApiFp(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceOptions(options?: any): AxiosPromise<void> {
            return localVarFp.getServiceOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CORSApi - object-oriented interface
 * @export
 * @class CORSApi
 * @extends {BaseAPI}
 */
export class CORSApi extends BaseAPI {
    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public getServiceOptions(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).getServiceOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetMidwayUserApi - axios parameter creator
 * @export
 */
export const GetMidwayUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Midway User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMidwayUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetMidwayUserApi - functional programming interface
 * @export
 */
export const GetMidwayUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetMidwayUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Midway User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMidwayUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMidwayUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetMidwayUserApi - factory interface
 * @export
 */
export const GetMidwayUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetMidwayUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Midway User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMidwayUser(options?: any): AxiosPromise<string> {
            return localVarFp.getMidwayUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetMidwayUserApi - object-oriented interface
 * @export
 * @class GetMidwayUserApi
 * @extends {BaseAPI}
 */
export class GetMidwayUserApi extends BaseAPI {
    /**
     * 
     * @summary Get Midway User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetMidwayUserApi
     */
    public getMidwayUser(options?: AxiosRequestConfig) {
        return GetMidwayUserApiFp(this.configuration).getMidwayUser(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetServiceDetailsForReportApi - axios parameter creator
 * @export
 */
export const GetServiceDetailsForReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send Service Insights report through email
         * @param {ServiceDetailsRequest} serviceDetailsRequest Get Service Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceDetailsForReport: async (serviceDetailsRequest: ServiceDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceDetailsRequest' is not null or undefined
            assertParamExists('getServiceDetailsForReport', 'serviceDetailsRequest', serviceDetailsRequest)
            const localVarPath = `/sendReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetServiceDetailsForReportApi - functional programming interface
 * @export
 */
export const GetServiceDetailsForReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetServiceDetailsForReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send Service Insights report through email
         * @param {ServiceDetailsRequest} serviceDetailsRequest Get Service Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceDetailsForReport(serviceDetailsRequest: ServiceDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SIService>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceDetailsForReport(serviceDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetServiceDetailsForReportApi - factory interface
 * @export
 */
export const GetServiceDetailsForReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetServiceDetailsForReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Send Service Insights report through email
         * @param {ServiceDetailsRequest} serviceDetailsRequest Get Service Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceDetailsForReport(serviceDetailsRequest: ServiceDetailsRequest, options?: any): AxiosPromise<Array<SIService>> {
            return localVarFp.getServiceDetailsForReport(serviceDetailsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetServiceDetailsForReportApi - object-oriented interface
 * @export
 * @class GetServiceDetailsForReportApi
 * @extends {BaseAPI}
 */
export class GetServiceDetailsForReportApi extends BaseAPI {
    /**
     * 
     * @summary Send Service Insights report through email
     * @param {ServiceDetailsRequest} serviceDetailsRequest Get Service Details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetServiceDetailsForReportApi
     */
    public getServiceDetailsForReport(serviceDetailsRequest: ServiceDetailsRequest, options?: AxiosRequestConfig) {
        return GetServiceDetailsForReportApiFp(this.configuration).getServiceDetailsForReport(serviceDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfraManualOverrideApi - axios parameter creator
 * @export
 */
export const InfraManualOverrideApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Manual override Infrastructure values from UI
         * @param {InfraManualOverrideModel} infraManualOverrideModel Manual override Infrastructure values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infraManualOverride: async (infraManualOverrideModel: InfraManualOverrideModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'infraManualOverrideModel' is not null or undefined
            assertParamExists('infraManualOverride', 'infraManualOverrideModel', infraManualOverrideModel)
            const localVarPath = `/service/infraManualOverride`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(infraManualOverrideModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfraManualOverrideApi - functional programming interface
 * @export
 */
export const InfraManualOverrideApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfraManualOverrideApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Manual override Infrastructure values from UI
         * @param {InfraManualOverrideModel} infraManualOverrideModel Manual override Infrastructure values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infraManualOverride(infraManualOverrideModel: InfraManualOverrideModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfraManualOverrideModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infraManualOverride(infraManualOverrideModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfraManualOverrideApi - factory interface
 * @export
 */
export const InfraManualOverrideApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfraManualOverrideApiFp(configuration)
    return {
        /**
         * 
         * @summary Manual override Infrastructure values from UI
         * @param {InfraManualOverrideModel} infraManualOverrideModel Manual override Infrastructure values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infraManualOverride(infraManualOverrideModel: InfraManualOverrideModel, options?: any): AxiosPromise<InfraManualOverrideModel> {
            return localVarFp.infraManualOverride(infraManualOverrideModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfraManualOverrideApi - object-oriented interface
 * @export
 * @class InfraManualOverrideApi
 * @extends {BaseAPI}
 */
export class InfraManualOverrideApi extends BaseAPI {
    /**
     * 
     * @summary Manual override Infrastructure values from UI
     * @param {InfraManualOverrideModel} infraManualOverrideModel Manual override Infrastructure values
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfraManualOverrideApi
     */
    public infraManualOverride(infraManualOverrideModel: InfraManualOverrideModel, options?: AxiosRequestConfig) {
        return InfraManualOverrideApiFp(this.configuration).infraManualOverride(infraManualOverrideModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SIServiceApi - axios parameter creator
 * @export
 */
export const SIServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a specific Service details
         * @param {ServiceDetailsRequest} serviceDetailsRequest Get Service Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceDetails: async (serviceDetailsRequest: ServiceDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceDetailsRequest' is not null or undefined
            assertParamExists('getServiceDetails', 'serviceDetailsRequest', serviceDetailsRequest)
            const localVarPath = `/service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SIServiceApi - functional programming interface
 * @export
 */
export const SIServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SIServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a specific Service details
         * @param {ServiceDetailsRequest} serviceDetailsRequest Get Service Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceDetails(serviceDetailsRequest: ServiceDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SIService>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceDetails(serviceDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SIServiceApi - factory interface
 * @export
 */
export const SIServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SIServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a specific Service details
         * @param {ServiceDetailsRequest} serviceDetailsRequest Get Service Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceDetails(serviceDetailsRequest: ServiceDetailsRequest, options?: any): AxiosPromise<Array<SIService>> {
            return localVarFp.getServiceDetails(serviceDetailsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SIServiceApi - object-oriented interface
 * @export
 * @class SIServiceApi
 * @extends {BaseAPI}
 */
export class SIServiceApi extends BaseAPI {
    /**
     * 
     * @summary Get a specific Service details
     * @param {ServiceDetailsRequest} serviceDetailsRequest Get Service Details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SIServiceApi
     */
    public getServiceDetails(serviceDetailsRequest: ServiceDetailsRequest, options?: AxiosRequestConfig) {
        return SIServiceApiFp(this.configuration).getServiceDetails(serviceDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SIServicesApi - axios parameter creator
 * @export
 */
export const SIServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get/service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SIServicesApi - functional programming interface
 * @export
 */
export const SIServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SIServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SIServicesApi - factory interface
 * @export
 */
export const SIServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SIServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary List all services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServices(options?: any): AxiosPromise<Array<ServiceConfig>> {
            return localVarFp.listServices(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SIServicesApi - object-oriented interface
 * @export
 * @class SIServicesApi
 * @extends {BaseAPI}
 */
export class SIServicesApi extends BaseAPI {
    /**
     * 
     * @summary List all services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SIServicesApi
     */
    public listServices(options?: AxiosRequestConfig) {
        return SIServicesApiFp(this.configuration).listServices(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceConfigsApi - axios parameter creator
 * @export
 */
export const ServiceConfigsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all service configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceConfigs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceConfigsApi - functional programming interface
 * @export
 */
export const ServiceConfigsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceConfigsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all service configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceConfigs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceConfigs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceConfigsApi - factory interface
 * @export
 */
export const ServiceConfigsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceConfigsApiFp(configuration)
    return {
        /**
         * 
         * @summary List all service configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceConfigs(options?: any): AxiosPromise<Array<ServiceConfig>> {
            return localVarFp.getServiceConfigs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceConfigsApi - object-oriented interface
 * @export
 * @class ServiceConfigsApi
 * @extends {BaseAPI}
 */
export class ServiceConfigsApi extends BaseAPI {
    /**
     * 
     * @summary List all service configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceConfigsApi
     */
    public getServiceConfigs(options?: AxiosRequestConfig) {
        return ServiceConfigsApiFp(this.configuration).getServiceConfigs(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdateInfraSettingsApi - axios parameter creator
 * @export
 */
export const UpdateInfraSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Infra Settings from UI
         * @param {UpdateInfraModel} updateInfraModel Infrastructure Settings to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfraSettings: async (updateInfraModel: UpdateInfraModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateInfraModel' is not null or undefined
            assertParamExists('updateInfraSettings', 'updateInfraModel', updateInfraModel)
            const localVarPath = `/service/updateInfraSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInfraModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateInfraSettingsApi - functional programming interface
 * @export
 */
export const UpdateInfraSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateInfraSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Infra Settings from UI
         * @param {UpdateInfraModel} updateInfraModel Infrastructure Settings to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInfraSettings(updateInfraModel: UpdateInfraModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInfraModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInfraSettings(updateInfraModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdateInfraSettingsApi - factory interface
 * @export
 */
export const UpdateInfraSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateInfraSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Infra Settings from UI
         * @param {UpdateInfraModel} updateInfraModel Infrastructure Settings to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfraSettings(updateInfraModel: UpdateInfraModel, options?: any): AxiosPromise<UpdateInfraModel> {
            return localVarFp.updateInfraSettings(updateInfraModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateInfraSettingsApi - object-oriented interface
 * @export
 * @class UpdateInfraSettingsApi
 * @extends {BaseAPI}
 */
export class UpdateInfraSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Update Infra Settings from UI
     * @param {UpdateInfraModel} updateInfraModel Infrastructure Settings to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateInfraSettingsApi
     */
    public updateInfraSettings(updateInfraModel: UpdateInfraModel, options?: AxiosRequestConfig) {
        return UpdateInfraSettingsApiFp(this.configuration).updateInfraSettings(updateInfraModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdateOtherInfoApi - axios parameter creator
 * @export
 */
export const UpdateOtherInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Other Info from UI
         * @param {UpdateOtherInfoModel} updateOtherInfoModel Other information to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOtherInfo: async (updateOtherInfoModel: UpdateOtherInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOtherInfoModel' is not null or undefined
            assertParamExists('updateOtherInfo', 'updateOtherInfoModel', updateOtherInfoModel)
            const localVarPath = `/otherInfo/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOtherInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateOtherInfoApi - functional programming interface
 * @export
 */
export const UpdateOtherInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateOtherInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Other Info from UI
         * @param {UpdateOtherInfoModel} updateOtherInfoModel Other information to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOtherInfo(updateOtherInfoModel: UpdateOtherInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateOtherInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOtherInfo(updateOtherInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdateOtherInfoApi - factory interface
 * @export
 */
export const UpdateOtherInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateOtherInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Other Info from UI
         * @param {UpdateOtherInfoModel} updateOtherInfoModel Other information to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOtherInfo(updateOtherInfoModel: UpdateOtherInfoModel, options?: any): AxiosPromise<UpdateOtherInfoModel> {
            return localVarFp.updateOtherInfo(updateOtherInfoModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateOtherInfoApi - object-oriented interface
 * @export
 * @class UpdateOtherInfoApi
 * @extends {BaseAPI}
 */
export class UpdateOtherInfoApi extends BaseAPI {
    /**
     * 
     * @summary Update Other Info from UI
     * @param {UpdateOtherInfoModel} updateOtherInfoModel Other information to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateOtherInfoApi
     */
    public updateOtherInfo(updateOtherInfoModel: UpdateOtherInfoModel, options?: AxiosRequestConfig) {
        return UpdateOtherInfoApiFp(this.configuration).updateOtherInfo(updateOtherInfoModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdateRootcauseApi - axios parameter creator
 * @export
 */
export const UpdateRootcauseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Rootcauses Table from UI
         * @param {UpdateRootcauseModel} updateRootcauseModel Rootcause to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceRootcauses: async (updateRootcauseModel: UpdateRootcauseModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRootcauseModel' is not null or undefined
            assertParamExists('updateServiceRootcauses', 'updateRootcauseModel', updateRootcauseModel)
            const localVarPath = `/rootcause/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRootcauseModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateRootcauseApi - functional programming interface
 * @export
 */
export const UpdateRootcauseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateRootcauseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Rootcauses Table from UI
         * @param {UpdateRootcauseModel} updateRootcauseModel Rootcause to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceRootcauses(updateRootcauseModel: UpdateRootcauseModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceRootcauses(updateRootcauseModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdateRootcauseApi - factory interface
 * @export
 */
export const UpdateRootcauseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateRootcauseApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Rootcauses Table from UI
         * @param {UpdateRootcauseModel} updateRootcauseModel Rootcause to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceRootcauses(updateRootcauseModel: UpdateRootcauseModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateServiceRootcauses(updateRootcauseModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateRootcauseApi - object-oriented interface
 * @export
 * @class UpdateRootcauseApi
 * @extends {BaseAPI}
 */
export class UpdateRootcauseApi extends BaseAPI {
    /**
     * 
     * @summary Update Rootcauses Table from UI
     * @param {UpdateRootcauseModel} updateRootcauseModel Rootcause to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateRootcauseApi
     */
    public updateServiceRootcauses(updateRootcauseModel: UpdateRootcauseModel, options?: AxiosRequestConfig) {
        return UpdateRootcauseApiFp(this.configuration).updateServiceRootcauses(updateRootcauseModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdateServiceActionItemsApi - axios parameter creator
 * @export
 */
export const UpdateServiceActionItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Action Items Table from UI
         * @param {UpdateAIModel} updateAIModel Action Item to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceActionItems: async (updateAIModel: UpdateAIModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAIModel' is not null or undefined
            assertParamExists('updateServiceActionItems', 'updateAIModel', updateAIModel)
            const localVarPath = `/actionitem/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAIModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateServiceActionItemsApi - functional programming interface
 * @export
 */
export const UpdateServiceActionItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateServiceActionItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Action Items Table from UI
         * @param {UpdateAIModel} updateAIModel Action Item to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceActionItems(updateAIModel: UpdateAIModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsightsAIModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceActionItems(updateAIModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdateServiceActionItemsApi - factory interface
 * @export
 */
export const UpdateServiceActionItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateServiceActionItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Action Items Table from UI
         * @param {UpdateAIModel} updateAIModel Action Item to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceActionItems(updateAIModel: UpdateAIModel, options?: any): AxiosPromise<Array<InsightsAIModel>> {
            return localVarFp.updateServiceActionItems(updateAIModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateServiceActionItemsApi - object-oriented interface
 * @export
 * @class UpdateServiceActionItemsApi
 * @extends {BaseAPI}
 */
export class UpdateServiceActionItemsApi extends BaseAPI {
    /**
     * 
     * @summary Update Action Items Table from UI
     * @param {UpdateAIModel} updateAIModel Action Item to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateServiceActionItemsApi
     */
    public updateServiceActionItems(updateAIModel: UpdateAIModel, options?: AxiosRequestConfig) {
        return UpdateServiceActionItemsApiFp(this.configuration).updateServiceActionItems(updateAIModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdateServiceConfigApi - axios parameter creator
 * @export
 */
export const UpdateServiceConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update ServiceConfigs form UI
         * @param {ServiceConfig} serviceConfig Service Config to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceConfigs: async (serviceConfig: ServiceConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceConfig' is not null or undefined
            assertParamExists('updateServiceConfigs', 'serviceConfig', serviceConfig)
            const localVarPath = `/service/updateConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateServiceConfigApi - functional programming interface
 * @export
 */
export const UpdateServiceConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateServiceConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update ServiceConfigs form UI
         * @param {ServiceConfig} serviceConfig Service Config to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceConfigs(serviceConfig: ServiceConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceConfigs(serviceConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdateServiceConfigApi - factory interface
 * @export
 */
export const UpdateServiceConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateServiceConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary Update ServiceConfigs form UI
         * @param {ServiceConfig} serviceConfig Service Config to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceConfigs(serviceConfig: ServiceConfig, options?: any): AxiosPromise<ServiceConfig> {
            return localVarFp.updateServiceConfigs(serviceConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateServiceConfigApi - object-oriented interface
 * @export
 * @class UpdateServiceConfigApi
 * @extends {BaseAPI}
 */
export class UpdateServiceConfigApi extends BaseAPI {
    /**
     * 
     * @summary Update ServiceConfigs form UI
     * @param {ServiceConfig} serviceConfig Service Config to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateServiceConfigApi
     */
    public updateServiceConfigs(serviceConfig: ServiceConfig, options?: AxiosRequestConfig) {
        return UpdateServiceConfigApiFp(this.configuration).updateServiceConfigs(serviceConfig, options).then((request) => request(this.axios, this.basePath));
    }
}


