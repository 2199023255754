import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import * as tokens from '@amzn/awsui-design-tokens';
import logoUrl from '../images/alexainsights.png';
import {SIMidwayUserFactory} from "../si-service-api/SIServicesApiFactory";
import {useEffect, useState} from "react";

export default function CommonHeader() {
    const SIMidwayApi = SIMidwayUserFactory();
    const [midwayUser, setMidwayUser] = useState("");

    useEffect(() => {
        (async () => {
            setMidwayUser((await (SIMidwayApi.getMidwayUser())).data);
        })();
    }, []);

    return (
        <div style={{background: tokens.colorBackgroundHomeHeader, padding: 7, height: 60}}>
            <Box padding={{vertical: 'xs', horizontal: 'xs'}}>
                <Grid
                    gridDefinition={[
                        {colspan: {l: 10, xxs: 10}},
                        {colspan: {l: 2, xxs: 2}}
                    ]}
                >
                    <img src={logoUrl} style={{height: 50, width: 185,}} alt="Alexa Insights logo"/>
                    <p style={{color: "lightblue", fontSize: "medium"}}>Hi, {midwayUser}@!</p>
                </Grid>
            </Box>
        </div>
    );
}