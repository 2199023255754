import { Route, Switch } from 'react-router-dom';
import Homepage from './home';
import CommonHeader from './CommonHeader';

export default function App() {

  return (
    <div>
      <CommonHeader />
      <Switch>
        <Route exact path="/" component={Homepage} />
      </Switch>
    </div>
  );
}
