import Table from "@amzn/awsui-components-react/polaris/table";
import {Box, Header} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import * as React from "react";

//Displays breakdown of tickets as per severity for selected service and month
export default function TicketsTableContent({serviceDetails, currentMonthTickets}) {

    return (
        <div>
            <Table
                columnDefinitions={[
                    // {
                    //     id: "service",
                    //     header: "Service Name",
                    //     cell: item => item.service || "-",
                    //     sortingField: "service"
                    // },
                    {
                        id: "sev2",
                        header: "Sev-2",
                        cell: item => item.sev2 || "-",
                        sortingField: "sev2"
                    },
                    {
                        id: "sev3",
                        header: "Sev-3",
                        cell: item => item.sev3 || "-",
                        sortingField: "sev3"
                    },
                    {
                        id: "sev4",
                        header: "Sev-4",
                        cell: item => item.sev4 || "-",
                        sortingField: "sev4"
                    },
                    {
                        id: "sev5",
                        header: "Sev-5",
                        cell: item => item.sev5 || "-",
                        sortingField: "sev5"
                    },
                    {
                        id: "total",
                        header: "Total",
                        cell: item => item.total || "-",
                        sortingField: "total"
                    }
                ]}
                items={[
                    {
                        // service: serviceDetails.fleetName,
                        sev2: currentMonthTickets?.sev2,
                        sev3: currentMonthTickets?.sev3,
                        sev4: currentMonthTickets?.sev4,
                        sev5: currentMonthTickets?.sev5,
                        total: <b>{currentMonthTickets?.incomings}</b>
                    }
                ]}
                loadingText="Loading resources"
                sortingDisabled
                variant="container"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No resources</b>
                        <Box
                            padding={{bottom: "s"}}
                            variant="p"
                            color="inherit"
                        >
                            No resources to display.
                        </Box>
                        <Button>Create resource</Button>
                    </Box>
                }
                header={<Header> Tickets Split up </Header>}
            />
        </div>
    );
}