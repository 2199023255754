import Table from "@amzn/awsui-components-react/polaris/table";
import {ServiceConfig} from "../../si-service-api/generated-src";
import {
    Box,
    ColumnLayout,
    FormField,
    Header,
    Input,
    Modal, SpaceBetween, Spinner,
    TokenGroup
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import * as React from "react";
import {useEffect, useState} from "react";
import SIServicesApiFactory, {UpdateServiceConfigsApiFactory} from "../../si-service-api/SIServicesApiFactory";

export default function ServiceConfigurationContent({allServices}) {

    const UpdateServiceConfigApi = UpdateServiceConfigsApiFactory();

    const [allServicesData, setAllServicesData] = useState<ServiceConfig[]>();
    const [modalCfgVisible, setModalCfgVisible] = React.useState(false);
    const [modalCfgEdit, isModalCfgEdit] = React.useState(true);
    const [modalCfgData, setModalCfgData] = useState<ServiceConfig>();
    const [notifiers, setNotifiers] = React.useState([]);
    const [newNotifier, setNewNotifier] = React.useState("");
    const [serviceListed, setServiceListed] = useState(false);
    const [allServicesInfo, setAllServices] = useState<ServiceConfig[]>();
    const SIServicesApi = SIServicesApiFactory();

    useEffect(() => {
        (async () => {
            setAllServices((await (SIServicesApi.listServices())).data);
            setServiceListed(true);
        })();
    }, []);

    useEffect(() => {
        setAllServicesData(allServices);
    }, []);

    //to populate service configuration via backend lambda
    function populateServiceConfigs() {
        var serviceConfigs: any = []
        allServicesData?.forEach((item) => {
            var notifierList: any = []
            if (item.notifiers != undefined) {
                item.notifiers.forEach((notifier) => {
                    notifierList.push({
                        label: notifier
                    });
                })

            }

            serviceConfigs.push({
                fleetId: item.fleetId,
                fleetName: item.fleetName,
                resolverGroup: item.resolverGroup,
                notifiers: notifierList,
                action: <Button iconName="edit" onClick={e => {
                    isModalCfgEdit(true)
                    setModalCfgVisible(true)
                    setModalCfgData(item)
                    if (item.notifiers != undefined) {
                        setNotifiers(notifierList)
                    }
                }} variant="icon"> </Button>
            })
        });
        return serviceConfigs;
    }

    //to update service configuration when user adds/deletes information
    function updateServiceConfigs() {
        var notifierList: any = []
        notifiers.forEach((notifi) => {
            notifierList.push(notifi["label"]);
        })

        UpdateServiceConfigApi.updateServiceConfigs({
            fleetId: modalCfgData?.fleetId,
            fleetName: modalCfgData?.fleetName,
            resolverGroup: modalCfgData?.resolverGroup,
            primaryOwner: modalCfgData?.primaryOwner,
            supportOwner: modalCfgData?.supportOwner,
            notifiers: notifierList
        }).then((response) => {
            setModalCfgVisible(false)
            var configs: any = []
            configs = response.data
            setAllServicesData(configs)
            populateServiceConfigs();
        });
    }

    //internal function that is called when user adds additional notifiers for sending email
    function addNotifiers() {
        var notifiersTmp: any = []
        notifiers?.forEach((notify) => {
            notifiersTmp.push(notify)
        });
        notifiersTmp.push({
            label: newNotifier
        })
        setNotifiers(notifiersTmp);
        setNewNotifier("");
    }

    //Displaying content on configuration tab along with pop up for adding information per service
    return (
        <div>

                <div>
                    {serviceListed ?
                    <Table
                        columnDefinitions={[
                            {
                                id: "fleetId",
                                header: "Fleet ID",
                                cell: (item: ServiceConfig) => item.fleetId || "-",
                                sortingField: "fleetId"
                            },
                            {
                                id: "fleetName",
                                header: "Fleet Name",
                                cell: (item: ServiceConfig) => item.fleetName || "-",
                                sortingField: "fleetName"
                            },
                            {
                                id: "resolverGroup",
                                header: "Resolver Group",
                                cell: (item: ServiceConfig) => item.resolverGroup || "-",
                                sortingField: "resolverGroup"
                            },
                            {
                                id: "action",
                                header: "",
                                cell: (item: ServiceConfig) => item.action
                            }
                        ]}
                        items={populateServiceConfigs()}
                        loadingText="Loading resources"
                        sortingDisabled
                        variant="container"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No resources</b>
                                <Box
                                    padding={{bottom: "s"}}
                                    variant="p"
                                    color="inherit"
                                >
                                    No resources to display.
                                </Box>
                                <Button>Create resource</Button>
                            </Box>
                        }
                        header={<Header>
                            <SpaceBetween size="s">
                                <Button
                                    ariaLabel="Onboard a fleet"
                                    iconAlign="left"
                                    iconName="add-plus"
                                    variant="primary"
                                    onClick={e => {
                                        isModalCfgEdit(false)
                                        setModalCfgVisible(true)
                                        setModalCfgData({})
                                    }}
                                >
                                    Onboard a Fleet
                                </Button>
                            </SpaceBetween>
                        </Header>

                        }
                    />
                    : <div><Spinner/> Fetching Services...</div>}
                    <Modal
                        onDismiss={() => setModalCfgVisible(false)}
                        visible={modalCfgVisible}
                        closeAriaLabel="Close modal"
                        size="large"
                        footer={
                            <Box float="right">
                                <Button variant="link" onClick={e => {
                                    setModalCfgData({});
                                    setModalCfgVisible(false);
                                }}
                                >Cancel</Button> &nbsp;
                                <Button variant="primary" onClick={e => {

                                    updateServiceConfigs()
                                }}
                                >Save</Button> &nbsp;
                                <Button variant="primary" onClick={e => {
                                }}
                                >Delete</Button>
                            </Box>
                        }
                        header="Add / Update Fleet Configuration"
                    >
                        <div>
                            <ColumnLayout columns={3}>
                                <FormField
                                    label="Fleet ID"
                                >

                                    <Input
                                        value={modalCfgData?.fleetId ? modalCfgData.fleetId : ""}
                                        onChange={event => {
                                            setModalCfgData({
                                                fleetId: event.detail.value,
                                                fleetName: modalCfgData?.fleetName,
                                                resolverGroup: modalCfgData?.resolverGroup,
                                                primaryOwner: modalCfgData?.primaryOwner,
                                                supportOwner: modalCfgData?.supportOwner,
                                                notifiers: notifiers
                                            })
                                        }
                                        }
                                        disabled={modalCfgEdit}
                                    />

                                </FormField>
                                <FormField
                                    label="Fleet Name"
                                >
                                    <Input
                                        disabled={modalCfgEdit}
                                        value={modalCfgData?.fleetName ? modalCfgData.fleetName : ""}
                                        onChange={event => {
                                            setModalCfgData({
                                                fleetId: modalCfgData?.fleetId,
                                                fleetName: event.detail.value,
                                                resolverGroup: modalCfgData?.resolverGroup,
                                                primaryOwner: modalCfgData?.primaryOwner,
                                                supportOwner: modalCfgData?.supportOwner,
                                                notifiers: notifiers
                                            })
                                        }
                                        }
                                    />
                                </FormField>
                                <FormField
                                    label="Resolver Group"
                                >
                                    <Input
                                        value={modalCfgData?.resolverGroup ? modalCfgData.resolverGroup : ""}
                                        onChange={event => {
                                            setModalCfgData({
                                                fleetId: modalCfgData?.fleetId,
                                                fleetName: modalCfgData?.fleetName,
                                                resolverGroup: event.detail.value,
                                                primaryOwner: modalCfgData?.primaryOwner,
                                                supportOwner: modalCfgData?.supportOwner,
                                                notifiers: notifiers
                                            })
                                        }
                                        }
                                    />
                                </FormField>
                                <FormField
                                    label="Primary Owner"
                                >
                                    <Input
                                        value={modalCfgData?.primaryOwner ? modalCfgData.primaryOwner : ""}
                                        onChange={event => {
                                            setModalCfgData({
                                                fleetId: modalCfgData?.fleetId,
                                                fleetName: modalCfgData?.fleetName,
                                                resolverGroup: modalCfgData?.resolverGroup,
                                                primaryOwner: event.detail.value,
                                                supportOwner: modalCfgData?.supportOwner,
                                                notifiers: notifiers
                                            })
                                        }
                                        }
                                    />
                                </FormField>
                                <FormField
                                    label="Support Owner"
                                >
                                    <Input
                                        value={modalCfgData?.supportOwner ? modalCfgData.supportOwner : ""}
                                        onChange={event => {
                                            setModalCfgData({
                                                fleetId: modalCfgData?.fleetId,
                                                fleetName: modalCfgData?.fleetName,
                                                resolverGroup: modalCfgData?.resolverGroup,
                                                primaryOwner: modalCfgData?.primaryOwner,
                                                supportOwner: event.detail.value,
                                                notifiers: notifiers
                                            })
                                        }
                                        }
                                    />
                                </FormField>

                            </ColumnLayout>
                        </div>
                        <div>
                            <ColumnLayout columns={2}>
                                <div>
                                    <FormField
                                        label="Report Notifiers"
                                    >
                                        <Input
                                            value={newNotifier}
                                            onChange={event => {
                                                setNewNotifier(event.detail.value)
                                            }
                                            }
                                        />
                                        <TokenGroup
                                            onDismiss={({detail: {itemIndex}}) => {
                                                setNotifiers([
                                                    ...notifiers.slice(0, itemIndex),
                                                    ...notifiers.slice(itemIndex + 1)
                                                ]);
                                            }}
                                            items={notifiers}
                                        />
                                    </FormField>
                                </div>
                                <div style={{paddingTop: 25}}>
                                    <Button variant="primary" onClick={e => {
                                        addNotifiers()
                                    }}
                                    >Add</Button>
                                </div>
                            </ColumnLayout>
                        </div>
                    </Modal>


                </div>

        </div>
    );
}