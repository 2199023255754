import {
    Alert,
    Box,
    ColumnLayout,
    DatePicker,
    FormField, Input,
    Multiselect, MultiselectProps, Spinner,
    TokenGroup
} from "@amzn/awsui-components-react";
import {useEffect, useState} from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import SIServicesApiFactory, {
    ReportApiFactory,
} from "../../si-service-api/SIServicesApiFactory";
import {ServiceConfig} from "../../si-service-api/generated-src";

export default function ReportsContent(this: any) {

    const ReportApi = ReportApiFactory();
    const [
        selectedOptions,
        setSelectedOptions,
    ] = useState<MultiselectProps.Options>([]);


    const [serviceSelected, isServiceSelected] = useState(true);
    const [validEmailAddress, isValidEmailAddress] = useState(true);
    const [dateSelected, isDateSelected] = useState(true);
    const [visible, setVisible] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [notifiers, setNotifiers] = useState([]);
    const [newEmailAddress, setNewNotifier] = useState("");
    const [serviceListed, setServiceListed] = useState(false);
    const [allServices, setAllServices] = useState<ServiceConfig[]>();
    const [alertMessage, setAlertMessage] = useState("");
    const SIServicesApi = SIServicesApiFactory();

    useEffect(() => {
        (async () => {
            setAllServices((await (SIServicesApi.listServices())).data);
            setServiceListed(true);
        })();
    }, []);

    //checking if email is valid
    function isValidEmail(email) {
        let regex = /^[a-zA-Z0-9]+@amazon.[A-Za-z]+$/;
        if (regex.test(email)) {
            return true;
        }
        return false;
    }

    //function evoked when add button is clicked after adding additional email notifiers
    function addEmail() {
        if (isValidEmail(newEmailAddress)) {
            let emailIdTemp: any = []
            notifiers?.forEach((email) => {
                emailIdTemp.push(email)
            });
            emailIdTemp.push({
                label: newEmailAddress
            })
            setNotifiers(emailIdTemp);
            setNewNotifier("");

        } else {
            //in case of error
            isValidEmailAddress(false);
        }

    }

    //function to send report to email notifiers as defined in configuration tab or additional notifiers as defined in reports tab
    function getServiceDetailsForReport() {

        var emailId: any = []
        notifiers.forEach((email) => {
            emailId.push(email["label"]);
        })

        var flag = true;
        if ((selectedOptions?.length == 0)) {
            isServiceSelected(false);
            flag = false;
        } else {
            isServiceSelected(true);
        }


        if ((selectedMonth == undefined || selectedMonth == "")) {
            isDateSelected(false);
            flag = false;
        } else {
            isDateSelected(true);
        }

        if (flag) {
            isServiceSelected(true);
            isDateSelected(true);

            var selServices: any = [];
            selectedOptions?.map((sel) => {
                selServices.push(sel.label);
            });

            (async () => {
                ReportApi.getServiceDetailsForReport({
                    fleetNames: selServices,
                    month: selectedMonth!,
                    toEmails: emailId
                });

                setAlertMessage("Request submitted. Report will be sent via E-mail for " +
                    selServices + " to the configured recipients.");
                setVisible(true);
                const timeId = setTimeout(() => {
                    // After 3 seconds set the show value to false
                    setAlertMessage("");
                    setVisible(false);
                }, 10000)

                reset();
            })();
        }

    }

    //function to reset alert message after it reaches the timeout limit
    function reset() {
        setSelectedOptions([]);
        setSelectedMonth("");
    }

//UI for reports tab
    return (
        <Box padding={{top: 's', horizontal: 's', bottom: 'l'}}>
            {serviceListed ?
                <div>
                    <div>
                        <ColumnLayout columns={4} borders="all" variant="text-grid">
                            <div>
                                <FormField
                                    errorText={serviceSelected ? "" : "Please Select Service"}
                                    label="Service Name"
                                    stretch={true}
                                >
                                    <Multiselect
                                        selectedOptions={selectedOptions}
                                        onChange={({detail}) => {
                                            setSelectedOptions(detail.selectedOptions)
                                        }}
                                        deselectAriaLabel={e => `Remove ${e.label}`}
                                        options={
                                            allServices?.map(item => ({
                                                label: item.fleetName,
                                                value: item.fleetName
                                            }))}
                                        placeholder="Choose service(s)"
                                        selectedAriaLabel="Selected"
                                    />

                                </FormField>
                            </div>
                            <div>
                                <FormField
                                    errorText={dateSelected ? "" : "Please Select Month"}
                                    label="Month"
                                    stretch={true}
                                >
                                    <DatePicker
                                        onChange={({detail}) => setSelectedMonth(detail.value)}
                                        value={selectedMonth}
                                        openCalendarAriaLabel={selectedDate =>
                                            "Choose Date" +
                                            (selectedDate
                                                ? `, selected date is ${selectedDate}`
                                                : "")
                                        }
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                    />
                                </FormField>
                            </div>

                            <div>
                                <ColumnLayout columns={2}>
                                    <div>
                                        <FormField
                                            errorText={validEmailAddress ? "" : "Please enter a valid email address"}
                                            label="Email address"
                                            stretch={true}
                                            className={"form-inline"}
                                        >
                                            <Input
                                                type="email"
                                                value={newEmailAddress}
                                                onChange={event => {
                                                    setNewNotifier(event.detail.value)
                                                }}
                                                placeholder="Please enter full email"
                                                inputMode="email"
                                            />
                                            <TokenGroup
                                                onDismiss={({detail: {itemIndex}}) => {
                                                    setNotifiers([
                                                        ...notifiers.slice(0, itemIndex),
                                                        ...notifiers.slice(itemIndex + 1)
                                                    ]);
                                                }}
                                                items={notifiers}
                                            />
                                        </FormField>
                                    </div>

                                    <div style={{paddingTop: 25}}>

                                        <Button variant="primary" onClick={e => {
                                            addEmail()
                                        }}
                                        >Add</Button>

                                    </div>


                                </ColumnLayout>
                            </div>


                            <div style={{paddingTop: 25}}>
                                <Button onClick={e => {
                                    getServiceDetailsForReport();
                                }} variant="primary">Send Report</Button>
                            </div>


                        </ColumnLayout>
                    </div>

                    <br/>
                    <Alert
                        onDismiss={() => setVisible(false)}
                        visible={visible}
                        dismissAriaLabel="Close alert"
                        type="success"
                    >
                        {alertMessage}
                    </Alert>
                </div>
                : <div><Spinner/> Fetching Services...</div>}
            <br/>
        </Box>
    );


}
