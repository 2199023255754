import {InsightsCPUModel, InsightsTicket, MapPlot} from "../../si-service-api/generated-src";

export function populateMapData(mapPlotData: Array<MapPlot> | undefined) {
    var data: any = [];
    mapPlotData?.forEach((item) => {
        data.push(
            {
                'x': item.x,
                'y': item.y
            }
        );
    })
    return data;
}

export function populateXDomain(mapPlotData: Array<MapPlot> | undefined) {
    var data: any = [];
    mapPlotData?.forEach((item) => {
        data.push(item.x);
    })
    return data;
}

export function populateCPUmapPlots(cpuDetails: Array<InsightsCPUModel> | undefined) {
    var data: any = [];
    cpuDetails?.forEach((item) => {
        data.push(
            {
                'x': item.monthyear,
                'y': item.maxCpu
            }
        );
    });
    return data;
}

export function populateCPUXDomain(fleetTickets: Array<InsightsCPUModel> | undefined) {
    var data: any = [];
    fleetTickets?.forEach((item) => {
        data.push(item.monthyear);
    })
    return data;
}

export function populateTicketMapData(fleetTickets: Array<InsightsTicket> | undefined) {
    var data: any = [];
    fleetTickets?.forEach((item) => {
        data.push(
            {
                'x': item.monthyear,
                'y': item.incomings
            }
        );
    });
    return data;
}

export function populateRRmapPlots(fleetTickets: Array<InsightsTicket> | undefined) {
    var data: any = [];
    fleetTickets?.forEach((item) => {
        data.push(
            {
                'x': item.monthyear,
                'y': item.resolvedRate
            }
        );
    });
    return data;
}

export function populateTicketXDomain(fleetTickets: Array<InsightsTicket> | undefined) {
    var data: any = [];
    fleetTickets?.forEach((item) => {
        data.push(item.monthyear);
    })
    return data;
}

