import Select, {SelectProps} from "@amzn/awsui-components-react/polaris/select";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import {
    Alert,
    Box,
    Container, DatePicker, FormField,
    Header, Input, Modal,
    SpaceBetween, Spinner,
    StatusIndicator,
    Textarea
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import Table from "@amzn/awsui-components-react/polaris/table";
import {
    InsightsAIModel,
    InsightsCPUModel,
    InsightsTicket,
    ServiceConfig, SIOtherInfo,
    SIService
} from "../../si-service-api/generated-src";
import {useEffect, useState} from "react";
import SIServicesApiFactory, {
    SIActionItemsApiFactory,
    SIOtherInfoApiFactory,
    SIServiceDetailsApiFactory
} from "../../si-service-api/SIServicesApiFactory";
import InfraMetricsContent from "./InfraMetricsContent";
import ServiceMetricsContent from "./ServiceMetricsContent";
import ReportsContent from "./ReportsContent";
import ServiceConfigurationContent from "./ServiceConfigurationContent";
import TicketsTableContent from "./TicketsTableContent";
import FleetMetricsTableContent from "./FleetMetricsTableContent";
import RootcausesContent from "./RootcausesContent";

// The content in the main content area of the App layout
export default function HomepageContent() {
    const SIServicesApi = SIServicesApiFactory();
    const SIServiceDetailsApi = SIServiceDetailsApiFactory();

    const SIOtherInfoApi = SIOtherInfoApiFactory();
    const SIActionItemApi = SIActionItemsApiFactory();

    const [alertMsg, setAlertMsg] = useState("");
    const [visible, setVisible] = useState(true);

    const [serviceSelected, isServiceSelected] = useState(true);
    const [dateSelected, isDateSelected] = useState(true);
    const [serviceListed, setServiceListed] = useState(false);
    const [serviceLoading, setServiceLoading] = useState(false);
    const [showMsg, setShowMsg] = useState(false);


    const [modalVisible, setModalVisible] = useState(false);
    const [modalAIVisible, setModalAIVisible] = useState(false);
    const [modalCreate, isModalCreate] = useState(true);
    const [modalAIEdit, isModalAIEdit] = useState(true);
    const [modalData, setModalData] = useState("");
    const [modalAIData, setModalAIData] = useState("");

    const [modalAIOldData, setModalAIOldData] = useState<InsightsAIModel>({});
    const [modalAITT, setModalAITT] = useState("");
    const [moalAIOwner, setModalAIOwner] = useState("");
    const [modalOldData, setModalOldData] = useState("");
    const [modalLabel, setModalLabel] = useState("");
    const [modalType, setModalType] = useState("");

    const [selectedMonth, setSelectedMonth] = useState("");
    const [allServices, setAllServices] = useState<ServiceConfig[]>();
    const [serviceDetails, setServiceDetails] = useState<SIService>();
    const [currentMonthTickets, setCurrentTickets] = useState<InsightsTicket>();
    const [currentMonthCpu, setCurrentMonthCpu] = useState<InsightsCPUModel>();
    const [highlightsData, setHighlightsData] = useState<string[]>();
    const [lowlightsData, setLowlightsData] = useState<string[]>();
    const [notesData, setNotesData] = useState<string[]>();
    const [highlights, setHighlights] = useState<SIOtherInfo[]>([]);
    const [lowlights, setLowlights] = useState<SIOtherInfo[]>([]);
    const [notes, setNotes] = useState<SIOtherInfo[]>([]);
    const [actionItems, setActionItems] = useState<InsightsAIModel[]>([]);
    const [actionItemsData, setActionItemsData] = useState<InsightsAIModel[]>([]);
    const [
        selectedOption,
        setSelectedOption
    ] = useState<SelectProps.Option>({});
    const [
        selectedAIStatus,
        setSelectedAIStatus
    ] = useState<SelectProps.Option>({});

    useEffect(() => {
        (async () => {
            setAllServices((await (SIServicesApi.listServices())).data);
            setServiceListed(true);
        })();
    }, []);

    //function to populate highlights in the UI from the backend response
    function populateHighlights(highlights: Array<string> | undefined) {
        var highData: any = []
        highlights?.forEach((item) => {
            highData.push({
                otherInfo: item,
                action: <Button iconName="edit" onClick={e => {
                    setModalData(item);
                    setModalOldData(item);
                    setModalLabel("Edit Highlight");
                    setModalVisible(true);
                    setModalType("H");
                    isModalCreate(false);
                }} variant="icon"> </Button>
            })
        });
        setHighlights(highData);
    }

    //function to populate lowlights in the UI from the backend response
    function populateLowlights(lowlights: Array<string> | undefined) {
        var lowData: any = []
        lowlights?.forEach((item) => {
            lowData.push({
                otherInfo: item,
                action: <Button iconName="edit" onClick={e => {
                    setModalData(item);
                    setModalOldData(item);
                    setModalLabel("Edit Lowlight");
                    setModalVisible(true);
                    setModalType("L");
                }} variant="icon"> </Button>
            })
        });
        setLowlights(lowData);
    }

    //function to populate notes in the UI from the backend response
    function populateNotes(notesList: Array<string> | undefined) {
        var notes: any = []
        notesList?.forEach((item) => {
            notes.push({
                otherInfo: item,
                action: <Button iconName="edit" onClick={e => {
                    setModalData(item);
                    setModalOldData(item);
                    setModalLabel("Edit Note");
                    setModalVisible(true);
                    setModalType("N");
                }} variant="icon"> </Button>
            })
        });
        setNotes(notes);
    }

    //function to populate action items in the UI from the backend response
    function populateActionItems(actionItems: Array<InsightsAIModel> | undefined) {
        var data: any = [];
        actionItems?.forEach((item) => {
            var statusType: any;

            if (item.status == "Not Started")
                statusType = "stopped"
            else if (item.status == "Work In Progress")
                statusType = "in-progress"
            else if (item.status == "Blocked/Review")
                statusType = "info"
            else if (item.status == "Resolved")
                statusType = "success"
            data.push(
                {
                    'description': item.description,
                    'trackingTT': item.trackingTT,
                    'status': <StatusIndicator type={statusType}>{item.status}</StatusIndicator>,
                    'owner': item.owner,
                    'action':
                        <Button iconName="edit" onClick={e => {
                            setModalAIData(item.description ? item.description : "");
                            setModalAITT(item.trackingTT ? item.trackingTT : "");
                            setSelectedAIStatus(item.status ? {'label': item.status, 'value': item.status} : {});
                            setModalAIOwner(item.owner ? item.owner : "");
                            setModalAIVisible(true);
                            isModalAIEdit(true);
                            setModalAIOldData({
                                description: item.description,
                                trackingTT: item.trackingTT,
                                status: item.status,
                                owner: item.owner
                            })
                        }} variant="icon"> </Button>
                }
            );
        });
        return data;
    }

    //maintaining a copy of action items in order to allow to
    function populateActionItemsData(actionItems: Array<InsightsAIModel> | undefined) {
        var actionItemsData: any = [];
        actionItems?.forEach((item) => {
            actionItemsData.push(
                {
                    'description': item.description,
                    'trackingTT': item.trackingTT,
                    'status': item.status,
                    'owner': item.owner
                }
            );
        });
        return actionItemsData;
    }

    //function to get service details to display in the dropdown
    function getServiceDetails() {
        setServiceDetails(undefined);
        var proceed = true;
        if ((selectedOption.value == undefined || selectedOption.value == "")) {
            isServiceSelected(false);
            proceed = false;
        } else {
            isServiceSelected(true);
        }

        if ((selectedMonth == undefined || selectedMonth == "")) {
            isDateSelected(false);
            proceed = false;
        } else {
            isDateSelected(true);
        }

        if (proceed) {
            setServiceLoading(true);
            setShowMsg(false);
            isServiceSelected(true);
            isDateSelected(true);
            setCurrentMonthCpu({});
            setCurrentTickets({});
            const startTime = Date.now();
            (async () => {
                (await (SIServiceDetailsApi.getServiceDetails({
                    fleetNames: [selectedOption.value!],
                    month: selectedMonth!
                })
                    .then((response => {
                        const msElapsed = Date.now() - startTime;

                        setShowMsg(false);
                        let selServiceData = response.data[0];
                        console.log(selServiceData);
                        setServiceDetails(selServiceData);
                        setServiceLoading(false);
                        selServiceData.fleetTickets?.forEach(ticket => {
                            setCurrentTickets(ticket)
                        })
                        selServiceData.cpuUtilizationPlots?.forEach(cpu => {
                            setCurrentMonthCpu(cpu)
                        })

                        setHighlightsData(selServiceData.highlights);
                        setLowlightsData(selServiceData.lowlights);
                        setNotesData(selServiceData.notes);

                        populateHighlights(selServiceData.highlights);
                        populateLowlights(selServiceData.lowlights);
                        populateNotes(selServiceData.notes);

                        setActionItemsData(populateActionItemsData(selServiceData.actionItems))
                        setActionItems(populateActionItems(selServiceData.actionItems));

                    }))
                    .catch((err) => {
                        if (err.response != undefined) {
                            if (err.response.status == 503) {
                                setServiceLoading(false);
                                setShowMsg(true);
                                setAlertMsg("Request Failed. Please re-try.")
                            } else {
                                setServiceLoading(false);
                                setShowMsg(true);
                                setAlertMsg(err.response.status + " : " + err.response.data.message);
                            }
                        }
                    })));
            })();
        }

    }

    //function to populate data in the respective fields
    function addOtherInfo(type: string) {
        if (type == "H") {
            setModalLabel("Add Highlights");
        } else if (type == "L") {
            setModalLabel("Add Lowlights");
        } else if (type == "N") {
            setModalLabel("Add Note")
        }
        setModalType(type);
        setModalVisible(true);
        isModalCreate(true);
    }

    //to populate the edited information like highlights, lowlights, notes.
    function populateUpdatedInfoList(otherInfoListOld: Array<string> | undefined) {
        var otherInfoList: any = []
        if (otherInfoListOld == undefined) {
            otherInfoList.push(modalData);
        } else {
            if (otherInfoListOld.indexOf(modalData) > -1) {
                console.log("Info already found");
            } else {
                if (modalOldData == "") {
                    otherInfoListOld.push(modalData);
                }
                otherInfoListOld.forEach((item) => {
                    if (modalOldData != "" && item == modalOldData) {
                        item = modalData
                    }
                    otherInfoList.push(item);
                });
            }
        }
        return otherInfoList;
    }

    //to make API call to update edited information
    function saveOtherInfo() {
        var otherInfoList: any = []

        if (modalType == "H") {
            otherInfoList = populateUpdatedInfoList(serviceDetails?.highlights);
        } else if (modalType == "L") {
            otherInfoList = populateUpdatedInfoList(serviceDetails?.lowlights);
        } else if (modalType == "N") {
            otherInfoList = populateUpdatedInfoList(serviceDetails?.notes);
        }


        (SIOtherInfoApi.updateOtherInfo({
            otherInfo: otherInfoList,
            updateType: modalType,
            fleetName: serviceDetails?.fleetName,
            monthYear: serviceDetails?.currMonthYr
        }).then((response => {
            let otherInfoResponse = response.data;
            if (otherInfoResponse.updateType == "H") {
                populateHighlights(otherInfoResponse.otherInfo);
            } else if (otherInfoResponse.updateType == "L") {
                populateLowlights(otherInfoResponse.otherInfo);
            } else if (otherInfoResponse.updateType == "N") {
                populateNotes(otherInfoResponse.otherInfo);
            }
        })));

        setModalData("");
        setModalOldData("");
        setModalType("");
        setModalVisible(false);

    }

    //calling delete API to remove information
    function deleteOtherInfo(modalData: string, modalType: string) {
        let newList;
        if (modalType == "H") {
            newList = highlightsData?.filter((item) => item !== modalData);
        } else if (modalType == "L") {
            newList = lowlightsData?.filter((item) => item !== modalData);
        } else if (modalType == "N") {
            newList = notesData?.filter((item) => item !== modalData);
        }

        (SIOtherInfoApi.updateOtherInfo({
            otherInfo: newList,
            updateType: modalType,
            fleetName: serviceDetails?.fleetName,
            monthYear: serviceDetails?.currMonthYr
        }).then((response => {
            let otherInfoResponse = response.data;
            if (otherInfoResponse.updateType == "H") {
                setHighlightsData(otherInfoResponse.otherInfo);
                populateHighlights(otherInfoResponse.otherInfo);
            } else if (otherInfoResponse.updateType == "L") {
                setLowlightsData(otherInfoResponse.otherInfo);
                populateLowlights(otherInfoResponse.otherInfo);
            } else if (otherInfoResponse.updateType == "N") {
                setNotesData(otherInfoResponse.otherInfo);
                populateNotes(otherInfoResponse.otherInfo);
            }
        })));

        setModalData("");
        setModalOldData("");
        setModalType("");
        setModalVisible(false);

    }

//function to update action items
    function updateAIInfo() {
        if (modalAIOldData.description == undefined) {
            var actionItemsDataNew: any = [];
            actionItemsData?.forEach((item) => {
                actionItemsDataNew.push(
                    {
                        'description': item.description,
                        'trackingTT': item.trackingTT,
                        'status': item.status,
                        'owner': item.owner
                    }
                );
            });

            actionItemsDataNew.push({
                'description': modalAIData,
                'trackingTT': modalAITT,
                'status': selectedAIStatus.value,
                'owner': moalAIOwner
            })
        } else {
            var actionItemsDataNew: any = [];
            actionItemsData?.forEach((item) => {
                if (item.description == modalAIOldData.description) {
                    actionItemsDataNew.push(
                        {
                            'description': modalAIData,
                            'trackingTT': modalAITT,
                            'status': selectedAIStatus.value,
                            'owner': moalAIOwner
                        }
                    );
                } else {
                    actionItemsDataNew.push(
                        {
                            'description': item.description,
                            'trackingTT': item.trackingTT,
                            'status': item.status,
                            'owner': item.owner
                        }
                    );
                }

            });
        }

        (SIActionItemApi.updateServiceActionItems({
            fleetName: serviceDetails?.fleetName,
            actionItems: actionItemsDataNew
        }).then((response => {
            let updatedAIData = response.data;
            setActionItemsData(populateActionItemsData(updatedAIData))
            setActionItems(populateActionItems(updatedAIData))
        })));

        setModalAIData("");
        setModalAIOldData({});
        setModalAITT("");
        setModalAIVisible(false);
    }

    //to delete action items
    function deleteAIInfo() {

        let newList = actionItemsData?.filter((item) => item.description !== modalAIData);

        (SIActionItemApi.updateServiceActionItems({
            fleetName: serviceDetails?.fleetName,
            actionItems: newList
        }).then((response => {
            let updatedAIData = response.data;
            setActionItemsData(populateActionItemsData(updatedAIData))
            setActionItems(populateActionItems(updatedAIData))
        })));

        setModalAIData("");
        setModalAIOldData({});
        setModalAITT("");
        setModalAIVisible(false);
    }

    //displaying service tab on SI reports dashboard
    return (
        <Box padding={{top: 's', horizontal: 's', bottom: 'l'}}>
            <Container>
                <Tabs
                    tabs={[
                        {
                            label: "Service Insights",
                            id: "insights",
                            content:
                                <div>
                                    {serviceListed ?
                                        <ColumnLayout columns={3} borders="all" variant="text-grid">
                                            <div>
                                                <FormField
                                                    errorText={serviceSelected ? "" : "Please Select Service"}
                                                    label="Service Name"
                                                    stretch={true}
                                                >
                                                    <Select
                                                        selectedOption={selectedOption}
                                                        onChange={({detail}) => {
                                                            setSelectedOption(detail.selectedOption);
                                                        }
                                                        }
                                                        options={allServices?.map(item => ({
                                                            label: item.fleetName,
                                                            value: item.fleetName
                                                        }))}
                                                        selectedAriaLabel="Selected"
                                                        placeholder="Choose a Service"
                                                    />
                                                </FormField>
                                            </div>
                                            <div>
                                                <FormField
                                                    errorText={dateSelected ? "" : "Please Select Month"}
                                                    label="Month"
                                                    stretch={true}
                                                >
                                                    <DatePicker
                                                        onChange={({detail}) => setSelectedMonth(detail.value)}
                                                        value={selectedMonth}
                                                        openCalendarAriaLabel={selectedDate =>
                                                            "Choose Date" +
                                                            (selectedDate
                                                                ? `, selected date is ${selectedDate}`
                                                                : "")
                                                        }
                                                        nextMonthAriaLabel="Next month"
                                                        placeholder="YYYY/MMM"
                                                        previousMonthAriaLabel="Previous month"
                                                        todayAriaLabel="Today"
                                                    />
                                                </FormField>
                                            </div>
                                            <SpaceBetween direction="horizontal" size="xs">
                                                <div style={{paddingTop: 15}}>
                                                    <Button onClick={e => {
                                                        getServiceDetails()
                                                    }} variant="primary">Search</Button>
                                                </div>
                                            </SpaceBetween>
                                        </ColumnLayout>
                                        : <div><Spinner/> Fetching Services...</div>}
                                    <br/>
                                    {serviceLoading ? <div><Spinner/> Loading Service Details...</div> : ""}
                                    {showMsg ? <div>
                                        <Alert
                                            onDismiss={() => setVisible(false)}
                                            visible={visible}
                                            dismissAriaLabel="Close alert"
                                            type="warning"
                                        >
                                            {alertMsg}
                                        </Alert>
                                    </div> : ""}
                                    {serviceDetails ?
                                        <div>
                                            <ServiceMetricsContent
                                                serviceDetails={serviceDetails}/>
                                            <br/>
                                            <InfraMetricsContent serviceDetails={serviceDetails}
                                                                 currentMonthCpu={currentMonthCpu}/>
                                            <br/>
                                            <ColumnLayout columns={2} borders="all" variant="text-grid">
                                                <TicketsTableContent serviceDetails={serviceDetails}
                                                                     currentMonthTickets={currentMonthTickets}/>
                                                <FleetMetricsTableContent
                                                    serviceDetails={serviceDetails}/>
                                            </ColumnLayout>
                                            <br/>
                                            <RootcausesContent serviceDetails={serviceDetails}/>
                                            <br/>
                                            <Container header={
                                                <Header variant="h2">
                                                    Action Items
                                                </Header>
                                            }>
                                                <ColumnLayout columns={1}>
                                                    <div>
                                                        <Table
                                                            columnDefinitions={[
                                                                {
                                                                    id: "description",
                                                                    header: "Action Item",
                                                                    cell: (item: InsightsAIModel) => item.description || "-",
                                                                    sortingField: "description"
                                                                },
                                                                {
                                                                    id: "trackingTT",
                                                                    header: "Tracking / Related TT",
                                                                    cell: (item: InsightsAIModel) => item.trackingTT || "-",
                                                                    sortingField: "trackingTT"
                                                                },
                                                                {
                                                                    id: "status",
                                                                    header: "Status",
                                                                    cell: (item: InsightsAIModel) => item.status || "-",
                                                                    sortingField: "status"
                                                                },
                                                                {
                                                                    id: "owner",
                                                                    header: "Owner",
                                                                    cell: (item: InsightsAIModel) => item.owner || "-",
                                                                    sortingField: "owner"
                                                                },
                                                                {
                                                                    id: "action",
                                                                    header: "",
                                                                    cell: (item: InsightsAIModel) => item.action,
                                                                }
                                                            ]}

                                                            items={actionItems}

                                                            loadingText="Loading resources"
                                                            sortingDisabled
                                                            variant="container"
                                                            empty={
                                                                <Box textAlign="center" color="inherit">
                                                                    <b>No Action Items found</b>
                                                                </Box>
                                                            }
                                                        />
                                                        <br/>
                                                        <Button onClick={e => {
                                                            setModalAIVisible(true);
                                                            setModalAIOldData({});
                                                            setModalAIData("");
                                                            setModalAITT("");
                                                            setSelectedAIStatus({});
                                                            setModalAIOwner("");
                                                        }} variant="primary">Add Action Item</Button>
                                                    </div>
                                                </ColumnLayout>

                                                <Modal
                                                    onDismiss={() => setModalAIVisible(false)}
                                                    visible={modalAIVisible}
                                                    closeAriaLabel="Close modal"
                                                    footer={
                                                        <Box float="right">
                                                            <Button variant="link" onClick={e => {
                                                                setModalAIData("");
                                                                setModalAITT("");
                                                                setSelectedAIStatus({});
                                                                setModalAIOwner("");
                                                                setModalAIOldData({});
                                                                setModalAIVisible(false);
                                                            }}
                                                            >Cancel</Button> &nbsp;
                                                            <Button variant="primary" onClick={e => {
                                                                updateAIInfo();
                                                            }}
                                                            >Save</Button> &nbsp;
                                                            {modalAIEdit ?
                                                                <Button variant="primary" onClick={e => {
                                                                    deleteAIInfo();
                                                                }}
                                                                >Delete</Button> : ""}
                                                        </Box>
                                                    }
                                                    header="Add/Update Action Item"
                                                >
                                                    <SpaceBetween direction="vertical" size="s">
                                                        <FormField
                                                            label="Action Item description"
                                                        >
                                                            <Textarea
                                                                onChange={({detail}) => {
                                                                    setModalAIData(detail.value)
                                                                }}
                                                                value={modalAIData}
                                                                placeholder="Add Action Item"
                                                            />
                                                        </FormField>

                                                        <FormField
                                                            label="Tracking TT"
                                                        >
                                                            <Input
                                                                value={modalAITT}
                                                                onChange={event =>
                                                                    setModalAITT(event.detail.value)
                                                                }
                                                            />
                                                        </FormField>

                                                        <FormField
                                                            label="Status"
                                                        >
                                                            <Select
                                                                selectedOption={selectedAIStatus}
                                                                onChange={({detail}) =>
                                                                    setSelectedAIStatus(detail.selectedOption)
                                                                }
                                                                options={[
                                                                    {label: "Not Started", value: "Not Started"},
                                                                    {
                                                                        label: "Work In Progress",
                                                                        value: "Work In Progress"
                                                                    },
                                                                    {label: "Blocked/Review", value: "Blocked/Review"},
                                                                    {label: "Resolved", value: "Resolved"},
                                                                ]}
                                                                selectedAriaLabel="Selected"
                                                            />
                                                        </FormField>

                                                        <FormField
                                                            label="Owner"
                                                            description="Add only alias"
                                                        >
                                                            <Input
                                                                value={moalAIOwner}
                                                                onChange={event =>
                                                                    setModalAIOwner(event.detail.value)
                                                                }
                                                            />
                                                        </FormField>
                                                    </SpaceBetween>
                                                </Modal>
                                            </Container>
                                            <br/>
                                            <Container header={
                                                <Header variant="h2">
                                                    Other Information
                                                </Header>
                                            }>
                                                <div>
                                                    <ColumnLayout columns={3} borders="all" variant="text-grid">
                                                        <div>
                                                            <Table
                                                                columnDefinitions={[
                                                                    {
                                                                        id: "otherInfo",
                                                                        header: "Highlights",
                                                                        cell: (item: SIOtherInfo) => item.otherInfo || "-",
                                                                        sortingField: "otherInfo"
                                                                    },
                                                                    {
                                                                        id: "action",
                                                                        header: "Action",
                                                                        cell: (item: SIOtherInfo) => item.action || "-",
                                                                        sortingField: "action",
                                                                        width: 90
                                                                    }
                                                                ]}

                                                                items={highlights}

                                                                loadingText="Loading resources"
                                                                sortingDisabled
                                                                variant="container"
                                                                empty={
                                                                    <Box textAlign="center" color="inherit">
                                                                        <b>N/A</b>
                                                                    </Box>
                                                                }
                                                            />
                                                            <br/>
                                                            <Button variant="primary" onClick={e => {
                                                                addOtherInfo("H");
                                                            }}>Add Highlight</Button>
                                                        </div>
                                                        <div>
                                                            <Table
                                                                columnDefinitions={[
                                                                    {
                                                                        id: "otherInfo",
                                                                        header: "Lowlights",
                                                                        cell: (item: SIOtherInfo) => item.otherInfo || "-",
                                                                        sortingField: "otherInfo"
                                                                    },
                                                                    {
                                                                        id: "action",
                                                                        header: "Action",
                                                                        cell: (item: SIOtherInfo) => item.action || "-",
                                                                        sortingField: "action",
                                                                        width: 90
                                                                    }
                                                                ]}

                                                                items={lowlights}

                                                                loadingText="Loading resources"
                                                                sortingDisabled
                                                                variant="container"
                                                                empty={
                                                                    <Box textAlign="center" color="inherit">
                                                                        <b>N/A</b>
                                                                    </Box>
                                                                }
                                                            />
                                                            <br/>
                                                            <Button variant="primary" onClick={e => {
                                                                addOtherInfo("L");
                                                            }}>Add Lowlight</Button>
                                                        </div>
                                                        <div>
                                                            <Table
                                                                columnDefinitions={[
                                                                    {
                                                                        id: "otherInfo",
                                                                        header: "Notes",
                                                                        cell: (item: SIOtherInfo) => item.otherInfo || "-",
                                                                        sortingField: "otherInfo",
                                                                    },
                                                                    {
                                                                        id: "action",
                                                                        header: "Action",
                                                                        cell: (item: SIOtherInfo) => item.action || "-",
                                                                        sortingField: "action",
                                                                        width: 90
                                                                    }
                                                                ]}

                                                                items={notes}

                                                                loadingText="Loading resources"
                                                                sortingDisabled
                                                                variant="container"
                                                                empty={
                                                                    <Box textAlign="center" color="inherit">
                                                                        <b>N/A</b>
                                                                    </Box>
                                                                }
                                                            />
                                                            <br/>
                                                            <Button variant="primary" onClick={e => {
                                                                addOtherInfo("N");
                                                            }}>
                                                                Add Note
                                                            </Button>
                                                        </div>
                                                    </ColumnLayout>
                                                    <br/>
                                                    <Modal
                                                        onDismiss={() => setModalVisible(false)}
                                                        visible={modalVisible}
                                                        closeAriaLabel="Close modal"
                                                        footer={
                                                            <Box float="right">
                                                                <Button variant="link" onClick={e => {
                                                                    setModalData("");
                                                                    setModalVisible(false);
                                                                }}
                                                                >Cancel</Button> &nbsp;
                                                                <Button variant="primary" onClick={e => {
                                                                    saveOtherInfo();
                                                                }}
                                                                >Save</Button> &nbsp;
                                                                {!modalCreate ?
                                                                    <Button variant="primary" onClick={e => {
                                                                        deleteOtherInfo(modalData, modalType);
                                                                    }}
                                                                    >Delete</Button> : ""}
                                                            </Box>
                                                        }
                                                        header={modalLabel}
                                                    >
                                                        <FormField>
                                                            <Textarea
                                                                onChange={({detail}) => {
                                                                    setModalData(detail.value)
                                                                }}
                                                                value={modalData}
                                                                placeholder="Add Information"
                                                            />
                                                        </FormField>
                                                    </Modal>
                                                </div>
                                            </Container>
                                            <br/>
                                        </div>
                                        : ""}
                                </div>
                        },
                        {
                            label: "Reports",
                            id: "reports",
                            content: <ReportsContent/>
                        },
                        {
                            label: "Configuration",
                            id: "config",
                            content:
                                <ServiceConfigurationContent allServices={allServices}/>
                        },
                    ]}
                />

            </Container>
        </Box>
    );
}
